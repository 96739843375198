import React,{useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { Layout, WineTroubleshooting, Navbar, BigMenu, Seo } from '../components'

const sensoryEvaluation = [
    {
        title:"Sight",
        evaluations:[
            "Is it the right colour for the style?",
            "Is there oxidation?",
            "Watch your wine - there are some very obvious problems, but what may seem like a wine that’s not clearing – turbidity or cloudiness could also be an indication of contamination"
        ]
    },
    {
        title:"Smell",
        evaluations:[
            "80-90% of perceived taste is actually smell. The tongue only tells us salty, sweet, sour, bitter or umami"
        ]
    },
    {
        title:"Taste (if all else fails)",
        evaluations:[
            "Sweet or dry (complete fermentation?)",
            "Effervescence/Gassiness",
            "Tannin, Bitter, Acid, Mouthfeel, Weight"
        ]
    }
]

const commonIssues = [
    {
        id:0,
        issue:"Fermentation is stuck/sluggish",
        causes:
            [
                "Fermentation temperature is too high or too low",
                "Check for any hints of hydrogen sulphide (H2S) (Rotten Egg)",
                "Lack of oxygen available to the yeast",
                "Residual sulphite sanitizing solution in primary fermenter or carboy"
            ],
        corrections:
            [
                "Adjust temperature to recommended range",
                "If there are any signs of H2S, vigorously stir wine in an attempt to blow off any H2S and provide the yeast with any necessary oxygen",
                "Aerate must/wine by stirring or racking",
                "Try inoculating with a rehydrated yeast starter (see instructions)",
                "Ensure that all equipment is well rinsed before using"
            ],
        notes:"these tips should be used day 2-5. If the wine is still stuck after day 6, the fermentation is stuck and you should contact the RJS Craft Winemaking Product Support Advisor for assistance"
    },
    {
        id:1,
        issue:"Wine is fizzy or carbonated",
        causes:
            [
                "Incomplete alcohol fermentation",
                "Prickly taste sensation",
                "Wine was not properly degassed, residual CO2 gas still present in wine (where fermentation is complete)"
            ],
        corrections:
            [
                "Never bottle wine that is fizzy or carbonated",
                "Restart fermentation if stopped and let fermentation complete",
                "Degas wine by racking, stirring or using a vacuum pump"
            ]  
    },
    {
        id:2,
        issue:"Wine colour is browning",
        causes:
            [
                "Wine has suffered from oxidation due to defective or poor use of winemaking equipment, excessive exposure to air during processing or storage, or insufficient or untimely addition of sulphite",
                "White wine is brownish",
                "Red wine has brownish rim",
                "Wine smells like Sherry (acetaldehyde)"
            ],
        corrections:
            [
                "Excessive browning cannot be satisfactory corrected",
                "Review integrity of all winemaking equipment",
                "Review all sulphite addition protocols"
            ]  
    },
    {
        id:3,
        issue:"Wine smells vinegary or like acetone",
        causes:
            [
                "Acetobacter spoilage bacteria (early stage)",
                "Wine smells like vinegar (acetic acid – a volatile acid)"
            ],
        corrections:
            [
                "Do not bottle",
                "Review process and winemaking equipment",
                "Ensure that carboy or barrels are always kept topped up as acetobacter requires oxygen to thrive in wine",
                "Ensure that the required sulphite has been added to the wine",
                "If fruit flies are an issue prevent the carboy from coming in contact with them as fruit flies carry acetobacter bacteria."
            ]  
    },
    {
        id:4,
        issue:"Wine is cloudy (turbid)",
        causes:
            [
                "Incomplete or renewed fermentation",
                "Improper racking, clarification or degassing",
                "Wine was subjected to high temperatures, i.e. wine, usually white, is not protein stable",
                "Yeast or bacterial contamination",
                "Wine has an off-aroma or off-flavour"
            ],
        corrections:
            [
                "Confirm by measuring the SG that fermentation is not complete.",
                "Let fermentation complete",
                "Ensure that SG is as per instructions before trying to clarify wine. Let wine settle until clear and rack carefully, and degas thoroughly; fine/filter as required",
                "If it is still not clearing, add a second package of kieselsol",
                "Add all the sulfite provided with the kit plus an additional ¼ tsp, and immediately filter wine down to fine grade"
            ]  
    },
    {
        id:5,
        issue:"Wine throws tartrate crystals in bottles",
        causes:
            [
                "Wine was subjected to very cold temperatures and it is not cold stable",
                "Colourless to yellowish (white wines) or reddish (in red wines) tartrate crystals that look like tiny shards of glass; found as precipitate or on cork surface"
            ],
        corrections:
            [
                "The wine is totally safe and can be drunk by carefully pouring it into a glass leaving crystals behind; there are no health concerns",
                "Review logs to ensure that metatartaric acid was added if supplied with kit",
                "Ensure wine is not stored below cellar temperature 13ºC (55ºF)",
                "NOTE: Metatartaric acid will hold up approx. one year – best for early drinking wines."
            ]  
    },
    {
        id:6,
        issue:"Corks are pushing out of bottles",
        causes:
            [
                "Storage temperature too high",
                "Corks are bulging out of bottles and from under capsules",
                "Will also cause wine in carboys to expand and spill into airlocks",
                "Possible renewed fermentation in bottle"
            ],
        corrections:
            [
                "Lower temperature to, ideally, 13°C",
                "Taste test one bottle to determine if wine has been damaged (oxidized)",
                "Measure specific gravity to ensure that fermentation is complete. If your wine kit did not come with a sweetening pack or finishing blend your SG should be 0.998 or lower",
            ]  
    },
    {
        id:7,
        issue:"Wine smells moldy/musty",
        causes:
            [
                "Mold interaction with chlorine; produces TCA (2,4,6-trichloroanisole) in what is best known as corked wine",
                "Wine smells musty, like wet newspaper or wet basement",
            ],
        corrections:
            [
                "Discard wine",
                "Review cleaning and sanitation process",
                "Measure specific gravity to ensure that fermentation is complete. If your wine kit did not come with a sweetening pack or finishing blend your SG should be 0.998 or lowerEliminate chlorine-based products (at least from winemaking area). TCA contaminates the entire area and can prove impossible to eradicate",
            ]  
    },
    {
        id:8,
        issue:"Wine has whitish film on surface",
        causes:
            [
                "Film Yeast Spoilage (Brettanomyces or Candida species)",
                "A whitish film (surface yeast) has formed on wine surfaces",
                "Wine smells like vinegar, barnyard, sherry or port"
            ],
        corrections:
            [
                "Rack wine to a few inches just below wine surface. Careful not to disturb film. Best you can hope for is a wine with a vinegar and/or nail-polish remover smell. Discard.",
                "Review process and winemaking equipment",
            ]  
    },
    {
        id:9,
        issue:"Filtering troubleshooting",
        causes:
            [
                "Filter pads are clogging; most common with reds or poorly clarified whites/reds.",
                "Wine is cloudy or has a lot of suspended particles",
                "Pressure reading on pressure gauge, if filtration system is so equipped, has exceeded the recommended maximum operating pressure"
            ],
        corrections:
            [
                "Allow the wine to completely clarify before filtering, and then rack to another carboy ensuring that sediments are not disturbed or racked",
                "Once completely clarified, first filter the wine using coarser pads followed by a second filtration using finer pads, e.g. Buon Vino #1 pads followed by #2 pads",
            ]  
    },
    {
        id:9,
        issue:"Wine referments in the bottle",
        causes:
            [
                "Wine was not fermented to dryness or was not adequately stabilized prior to bottling and residual sugar is now fermenting",
                "Corks may start to push or pop out",
                "Bottles can start to shatter"
            ],
        corrections:
            [
                "Uncork all bottles into a carboy and let fermentation complete to the recommended SG",
                "Stabilize and clarify once again before re-bottling. Ensure that the wine has completely fermented before adding fresh sulfite and potassium sorbate",
            ]  
    },
    {
        id:10,
        issue:"Wine smells of sulphur compounds",
        causes:
            [
                "Overuse of sulphite",
                "Wine smells like a burnt match; sulphur dioxide (SO2)",
                "Wine was in contact with gross lees for too long or yeast was overly stressed during fermentation from, for example, low/high temperatures",
                "Wine smells like rotten eggs; hydrogen sulphide (H2S)",
                "Wine smells like onions/garlic; mercaptans (thiols)",
                "Wine smells like cooked cabbage; dimethyl sulphide (DMS)."
            ],
        corrections:
            [
                "Aerate must/wine by stirring/racking to dissipate (bind) SO2",
                "Review all sulphite addition protocols",
                "For mild cases (burnt match smell), try a very light aeration by racking",
                "For more serious problems, discard and replace",
                "Review winemaking process"
            ]  
    },
    {
        id:11,
        issue:"Wine smells “funny”",
        causes:
            [
                "Brettanomyces yeast infection from unsanitary conditions",
                "Wine smells of barnyard, horses saddle, smoky, or leathery",
                "Wine was stored in glass/PET carboy in direct UV light (known as light-strike reaction)",
                "Wine smells of asparagus or corn chips"
            ],
        corrections:
            [
                "Untreatable",
                "If wine was stored in oak barrels, check barrels and discard if infected",
                "Review winemaking equipment and process to try and identify source of infection",
                "Store wine away from light sources"
            ]  
    },
]

const WineTroubleshootingPage = ({data}) => {
    const [hasMounted, setHasMounted] = useState(false)
    useEffect(()=>{
        setHasMounted(true)
    },[])
    if(!hasMounted)return null
    const evaluations = data.datoCmsWineTroubleshooting.sensoryEvaluations.map((evaluation)=>{
        const values = evaluation.evaluation.value.document.children.map((child)=>child.children[0].value)
        return{
            title:evaluation.title,
            evaluations:values
        }
    })
    const issues = data.datoCmsWineTroubleshooting.commonIssues.map((issue)=>{
        const causes = issue.causes.value.document.children.map((child)=>child.children[0].value.replace(/\xA0/g,' '))
        const corrections = issue.corrections.value.document.children.map((child)=>child.children[0].value.replace(/\xA0/g,' '))
        return {
            id:issue.id,
            issue:issue.issue,
            causes:causes,
            corrections:corrections
        }
    })
    const { title, description, image } = data.datoCmsWineTroubleshooting
    return (
        <Seo title={'Troubleshooting Your Crafted Wine - FAQ'}>
            <Layout>
                <Navbar/>
                <BigMenu/>
                <WineTroubleshooting newData={data} data={{title:title, description:description, image:image, issues:issues, evaluations:evaluations}}/>
            </Layout>
        </Seo>
    )
}

export default WineTroubleshootingPage

export const query = (graphql`
    query {
        datoCmsWineTroubleshooting {
            id
            description
            title
            image{
                sizes(maxWidth: 1920, imgixParams: { fm: "webp" }) {
                    ...GatsbyDatoCmsSizes
                }
            }
            sensoryEvaluations {
                title
                evaluation {
                  value
                }
              }
            commonIssues {
                id
                issue
                causes {
                        value
                    }
                corrections {
                        value
                    }
                }
        }
    }
 `)